import { useMemo } from "react";
import { useAppSelector } from "../../redux/hooks";
import { selectReferenceTrip } from "../../redux/slices/shed/shedSlice";

type Props = {
  label: "From" | "To";
  locationText: string | null;
};

const isPristine = (locationText: string | null) => locationText === null;

const useMapLocationDisplay = ({ label, locationText }: Props): string => {
  const referenceTrip = useAppSelector(selectReferenceTrip);

  return useMemo(() => {
    if (!referenceTrip) {
      return locationText || "";
    }
    const {
      locationFrom: { formattedAddress: formattedFromAddress },
      locationTo: { formattedAddress: formattedToAddress },
    } = referenceTrip;

    return label === "From" && formattedFromAddress && isPristine(locationText)
      ? formattedFromAddress
      : label === "To" && formattedToAddress && isPristine(locationText)
      ? formattedToAddress
      : !isPristine(locationText)
      ? locationText || ""
      : label === "From"
      ? locationText || formattedFromAddress || ""
      : label === "To"
      ? locationText || formattedToAddress || ""
      : "";
  }, [referenceTrip, label, locationText]);
};

export default useMapLocationDisplay;
