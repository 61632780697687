type Props = {
  carBrand: string;
  size: "small" | "large";
};

export const sizeToPixel = (size: "small" | "large"): number =>
  size === "large" ? 45 : size === "small" ? 20 : 20;

const CarLogoBrand = ({ carBrand, size }: Props) => (
  <img
    alt="brand-logo"
    style={{ width: sizeToPixel(size), height: sizeToPixel(size) }}
    src={process.env.PUBLIC_URL + `/brands/${carBrand}.svg`}
    onError={({ currentTarget }) => {
      currentTarget.onerror = null; // prevents looping
      currentTarget.src = process.env.PUBLIC_URL + `/brands/car.svg`;
    }}
  />
);

export default CarLogoBrand;
