import { useEffect, useCallback, useState, useRef } from "react";
import { styled, TextField } from "@mui/material";
import { MapLocation } from "../../types/map";
import useAutocomplete, {
  Autocomplete,
} from "../../hooks/maps/useAutocomplete";
import useMapLocationDisplay from "../../hooks/maps/useMapLocationDisplay";

const SearchField = styled(TextField)(() => ({
  flexGrow: 1,
}));

type Props = {
  label: "From" | "To";
  location: MapLocation;
  onChange: (newLocation: MapLocation) => void;
};

const PRISTINE_VALUE = null;

const MapLocationField = ({ label, location, onChange }: Props) => {
  const autocompleteRef = useRef<Autocomplete | null>(null);
  const searchFieldRef = useRef<HTMLInputElement | null>(null);
  const [locationText, setLocationText] = useState<string | null>(
    PRISTINE_VALUE
  );

  useEffect(() => {
    !location.formattedAddress && setLocationText(PRISTINE_VALUE);
  }, [location]);

  const displayText = useMapLocationDisplay({
    label,
    locationText,
  });

  const handleInitialized = useCallback((autocomplete: Autocomplete) => {
    autocompleteRef.current = autocomplete;
  }, []);

  const handlePlaceChange = useCallback(
    (autocomplete: Autocomplete) => {
      const { formatted_address, geometry } = autocomplete.getPlace();

      setLocationText((prevLocation) => formatted_address || prevLocation);
      onChange({
        formattedAddress: formatted_address ?? "",
        latitude: geometry?.location.lat() ?? null,
        longitude: geometry?.location.lng() ?? null,
      });
    },
    [onChange]
  );

  useAutocomplete({
    referenceBinding: searchFieldRef,
    onInitialized: handleInitialized,
    onPlaceChange: handlePlaceChange,
  });

  return (
    <SearchField
      inputRef={searchFieldRef}
      variant="filled"
      label={label}
      value={displayText}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        setLocationText(event.target.value)
      }
    />
  );
};

export default MapLocationField;
