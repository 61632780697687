import { useCallback } from "react";
import { CarState } from "types/cars";
import { MapItinerary } from "types/map";
import {
  addUserTrip,
  selectReferenceTripEmission,
  selectReferenceTrip,
  clearItinerary,
  registerReferenceTrip,
  updateMyUserTrips,
} from "redux/slices/shed/shedSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import useSaveTrip from "hooks/trips/useSaveTrip";
import { getNeutralTrip } from "libraries/trip";
import useDisplayMessage from "./useDisplayMessage";

type Props = {
  itinerary: MapItinerary | null;
  onSaved: (tripId: string, carId: string) => void;
};

type ReturnType = {
  handleSaveItineraryToTrip: (tripCar: CarState) => void;
  loading: boolean;
};

const useSaveItineraryToTrip = ({ onSaved, itinerary }: Props): ReturnType => {
  const dispatch = useAppDispatch();

  const { displayMessage } = useDisplayMessage();

  const { handleSaveTrip, loading } = useSaveTrip();

  const { totalKgEmission } = useAppSelector(selectReferenceTripEmission);

  const referenceTrip = useAppSelector(selectReferenceTrip);

  const handleSaveItineraryToTrip = useCallback(
    async (tripCar: CarState) => {
      if (!itinerary) {
        displayMessage("Remember to add a trip", "info");
        return;
      }

      const addedTrip = await handleSaveTrip({
        ...getNeutralTrip(),
        carId: tripCar.id,
        distanceMeters: itinerary.distanceMeters,
        distanceLabel: itinerary.distanceLabel,
        durationSeconds: itinerary.durationSeconds,
        fromAddress: itinerary.locationFrom.formattedAddress ?? "",
        fromLatitude: itinerary.locationFrom.latitude ?? 0,
        fromLongitude: itinerary.locationFrom.longitude ?? 0,
        licensePlate: tripCar.licensePlate,
        toAddress: itinerary.locationTo.formattedAddress ?? "",
        toLatitude: itinerary.locationTo.latitude ?? 0,
        toLongitude: itinerary.locationTo.longitude ?? 0,
        totalKgEmission,
        tripId: referenceTrip.tripId ?? "",
      });

      // Add new trip
      addedTrip && !referenceTrip.tripId && dispatch(addUserTrip(addedTrip));

      // Update existing trip
      addedTrip &&
        referenceTrip.tripId &&
        dispatch(updateMyUserTrips(addedTrip));

      addedTrip &&
        dispatch(
          registerReferenceTrip({
            tripId: addedTrip.tripId,
            tripLocation: null,
          })
        );
      addedTrip && dispatch(clearItinerary());

      addedTrip && onSaved(addedTrip.tripId, tripCar.id);
    },
    [
      handleSaveTrip,
      onSaved,
      dispatch,
      itinerary,
      totalKgEmission,
      referenceTrip.tripId,
      displayMessage,
    ]
  );

  return { handleSaveItineraryToTrip, loading };
};

export default useSaveItineraryToTrip;
