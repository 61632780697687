import React from "react";
import { Link } from "react-router-dom";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { Box, Button, styled, Typography } from "@mui/material";
import { AppContainer } from "../base-app/App";

const StyledImg = styled("img")`
  width: 50%;
  height: 50%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 2px;
  margin-bottom: 2px;
  max-width: 200px;
`;

const ThankYouPage = () => (
  <AppContainer sx={{ textAlign: "center" }}>
    <Box textAlign="center" maxWidth="100">
      <StyledImg
        alt="brand-logo"
        src={`${process.env.PUBLIC_URL}/logo_EN_horizontal_black.svg`}
      />
    </Box>
    <Box my={2}>
      <Typography variant="h5">Thank you for you donation.</Typography>
      <Typography variant="body2" color="text.secondary">
        Payment successful.
      </Typography>
    </Box>
    <Button
      startIcon={<DirectionsCarIcon />}
      variant="contained"
      color="success"
      component={Link}
      to="/"
    >
      home
    </Button>
  </AppContainer>
);

export default ThankYouPage;
