import { TripShare, TripLocation, TripState } from "types/user";

export const getNeutralTrip = (): TripState => ({
  carId: "",
  licensePlate: "",
  distanceMeters: 0,
  distanceLabel: "",
  durationSeconds: 0,
  fromAddress: "",
  fromLatitude: 0,
  fromLongitude: 0,
  toAddress: "",
  toLatitude: 0,
  toLongitude: 0,
  totalKgEmission: 0,
  tripId: "",
  userId: "",
  tripTimeStamp: "",
  commuteFrequency: 1,
});

export const tripStateToTripLocation = ({
  fromAddress,
  fromLatitude,
  fromLongitude,
  toAddress,
  toLatitude,
  toLongitude,
}: TripState): TripLocation => ({
  fromAddress,
  fromLatitude,
  fromLongitude,
  toAddress,
  toLatitude,
  toLongitude,
});

export const tripStateToTripShare = (
  {
    fromAddress,
    fromLatitude,
    fromLongitude,
    toAddress,
    toLatitude,
    toLongitude,
  }: TripLocation,
  licensePlate: string
): TripShare => ({
  fromAddress,
  fromLatitude,
  fromLongitude,
  licensePlate,
  toAddress,
  toLatitude,
  toLongitude,
});
