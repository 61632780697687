import { useLazyQuery } from "@apollo/client";
import { at } from "lodash/fp";
import { useCallback } from "react";
import { useAppSelector } from "../redux/hooks";

import { CAPTURE_PAYMENT } from "../graphql/queries";
import { selectReferenceCar } from "../redux/slices/shed/shedSlice";

type Props = {
  co2FootprintInKg: number;
  donation: number;
  distance: number;
  mobileNumber: string;
};

type ReturnType = {
  handleVippsPayment: (tripId: string, carId: string) => void;
  loadingPayment: boolean;
};

const useHandleVippsPayment = ({
  co2FootprintInKg,
  donation,
  distance,
  mobileNumber,
}: Props): ReturnType => {
  const [capturePayment, { loading: loadingPayment }] =
    useLazyQuery(CAPTURE_PAYMENT);

  const car = useAppSelector(selectReferenceCar);

  const handleVippsPayment = useCallback(
    async (tripId?: string, carId?: string) => {
      console.debug("[Component] Car Details - Vipps initiate payment");
      const { data } = await capturePayment({
        variables: {
          carId: carId ? carId : car ? car.id : null,
          tripId: tripId,
          licensePlate: car ? car?.licensePlate : null,
          carBrand: car?.carBrand,
          distance: parseFloat(distance.toFixed(2)),
          donationAmount: parseFloat(donation.toFixed(2)),
          co2FootprintInKg: parseFloat(co2FootprintInKg.toFixed(2)),
          mobileNumber: mobileNumber,
          fallbackUrl: window.location.origin.toString(),
        },
      });

      const [url] = at("capturePayment.urlRedirect")(data);

      if (!url) {
        console.warn(
          "[Component] useHandleVippsPayment - Vipps capture initiation rejected",
          donation
        );
      } else {
        window.location.replace(String(url));
      }
    },
    [co2FootprintInKg, capturePayment, donation, distance, car, mobileNumber]
  );

  return {
    handleVippsPayment,
    loadingPayment,
  };
};

export default useHandleVippsPayment;
