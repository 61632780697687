import { useState, useRef, useCallback } from "react";
import { add, debounce } from "lodash/fp";
import { TripState } from "types/user";
import useSaveFrequencyToTrip from "hooks/trips/useSaveFrequencyToTrip";

type Props = {
  trip: TripState;
};

type ReturnType = {
  commuteFrequency: number;
  increaseFrequency: () => void;
  decreaseFrequency: () => void;
};

const DEBOUNCE_FREQUENCY_CHANGE = 1000;

const useTripCommuteFrequency = ({ trip }: Props): ReturnType => {
  const { handleSaveFrequencyToTrip } = useSaveFrequencyToTrip({ trip });
  const frequencyToSave = useRef(trip.commuteFrequency ?? 1);
  const [localFrequency, setLocalFrequency] = useState(
    trip.commuteFrequency ?? 1
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSaveTrip = useCallback(
    debounce(DEBOUNCE_FREQUENCY_CHANGE, () =>
      handleSaveFrequencyToTrip(frequencyToSave.current)
    ),
    []
  );

  const handleIncreaseFrequency = useCallback(() => {
    frequencyToSave.current += 1;
    setLocalFrequency(add(1));
    debouncedSaveTrip();
  }, [debouncedSaveTrip]);

  const handleDecreaseFrequency = useCallback(() => {
    frequencyToSave.current -= 1;
    setLocalFrequency(add(-1));
    debouncedSaveTrip();
  }, [debouncedSaveTrip]);

  return {
    commuteFrequency: localFrequency,
    increaseFrequency: handleIncreaseFrequency,
    decreaseFrequency: handleDecreaseFrequency,
  };
};

export default useTripCommuteFrequency;
