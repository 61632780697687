import { useSearchParams } from "react-router-dom";
import usePostMount from "hooks/core/usePostMount";
import { registerReferenceTrip } from "redux/slices/shed/shedSlice";
import { TripShare } from "types/user";
import { useAppDispatch } from "redux/hooks";
import useCarSearch from "hooks/useCarSearch";

const useMapShareTripParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { handleSearchCar } = useCarSearch();

  usePostMount({
    handler: () => {
      const tripShare: TripShare = {
        licensePlate: searchParams.get("licensePlate") ?? "",
        fromAddress: searchParams.get("fromAddress") ?? "",
        fromLatitude: Number(searchParams.get("fromLatitude") ?? 0),
        fromLongitude: Number(searchParams.get("fromLongitude") ?? 0),
        toAddress: searchParams.get("toAddress") ?? "",
        toLatitude: Number(searchParams.get("toLatitude") ?? 0),
        toLongitude: Number(searchParams.get("toLongitude") ?? 0),
      };

      if (
        tripShare.licensePlate &&
        tripShare.fromAddress &&
        tripShare.fromLatitude &&
        tripShare.fromLongitude &&
        tripShare.toAddress &&
        tripShare.toLatitude &&
        tripShare.toLongitude
      ) {
        console.log("[Map] Shared trip consummed", tripShare);

        searchParams.delete("fromAddress");
        searchParams.delete("fromLatitude");
        searchParams.delete("fromLongitude");
        searchParams.delete("toAddress");
        searchParams.delete("toLatitude");
        searchParams.delete("toLongitude");
        searchParams.delete("licensePlate");
        setSearchParams(searchParams);

        dispatch(
          registerReferenceTrip({
            tripId: null,
            tripLocation: {
              fromAddress: tripShare.fromAddress,
              fromLatitude: tripShare.fromLatitude,
              fromLongitude: tripShare.fromLongitude,
              toAddress: tripShare.toAddress,
              toLatitude: tripShare.toLatitude,
              toLongitude: tripShare.toLongitude,
            },
          })
        );
        handleSearchCar(tripShare.licensePlate);
      }
    },
  });
};

export default useMapShareTripParams;
