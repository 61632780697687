import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateMyUserTrips } from "redux/slices/shed/shedSlice";
import { TripState } from "types/user";
import useSaveTrip from "hooks/trips/useSaveTrip";

type Props = {
  trip: TripState;
};

type ReturnType = {
  handleSaveFrequencyToTrip: (commuteFrequency: number) => void;
  loading: boolean;
};

const useSaveFrequencyToTrip = ({ trip }: Props): ReturnType => {
  const dispatch = useDispatch();

  const { handleSaveTrip, loading } = useSaveTrip();

  const handleSaveFrequencyToTrip = useCallback(
    async (commuteFrequency: number) => {
      const addedTrip = await handleSaveTrip({
        ...trip,
        commuteFrequency,
      });
      addedTrip && dispatch(updateMyUserTrips(addedTrip));
    },
    [handleSaveTrip, trip, dispatch]
  );

  return { handleSaveFrequencyToTrip, loading };
};

export default useSaveFrequencyToTrip;
