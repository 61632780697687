import { useState } from "react";

import MapPanelSearch from "./MapPanelSearch";
import MapContainer from "./MapContainer";
import MapMarker from "./MapMarker";
import MapPOI from "./MapPOI";
import useDirections from "../../hooks/maps/useDirections";
import useNavLocation from "../../hooks/maps/useNavLocation";
import { LatLng } from "../../types/map";
import MapDrawer from "../map/MapDrawer";
import { useAppSelector } from "../../redux/hooks";
import { selectReferenceTrip } from "../../redux/slices/shed/shedSlice";
import MapPanelActions from "./MapPanelActions";
import useMapShareTripParams from "hooks/maps/useMapShareTripParams";

const MapPage = () => {
  useMapShareTripParams();

  const [centerGeo, setCenterGeo] = useState<LatLng>({ lat: 0, lng: 0 });
  const [zoomLevel, setZoomLevel] = useState(3);
  const [shedIcon] = useState<google.maps.Icon | undefined>(undefined);
  const { locationFrom, locationTo } = useAppSelector(selectReferenceTrip);

  useDirections({
    from: locationFrom,
    to: locationTo,
  });

  useNavLocation({
    onInitCenterGeo: setCenterGeo,
    onInitZoomLevel: setZoomLevel,
  });

  return (
    <>
      <MapContainer
        center={
          locationFrom.latitude && locationFrom.longitude
            ? {
                lat: locationFrom.latitude ? locationFrom.latitude : 0,
                lng: locationFrom.longitude ? locationFrom.longitude : 0,
              }
            : centerGeo
        }
        zoom={zoomLevel}
        disableDefaultUI
        zoomControl
      >
        <MapMarker
          icon={shedIcon}
          position={
            locationFrom.latitude && locationFrom.longitude
              ? {
                  lat: locationFrom.latitude ? locationFrom.latitude : 0,
                  lng: locationFrom.longitude ? locationFrom.longitude : 0,
                }
              : centerGeo
          }
        />

        <MapMarker
          icon={shedIcon}
          position={{
            lat: locationTo.latitude ? locationTo.latitude : 0,
            lng: locationTo.longitude ? locationTo.longitude : 0,
          }}
        />

        <MapPOI lat={centerGeo.lat} lng={centerGeo.lng} />
      </MapContainer>
      <MapPanelSearch locationFrom={locationFrom} locationTo={locationTo} />
      <MapPanelActions />
      <MapDrawer />
    </>
  );
};

export default MapPage;
