import { useCallback } from "react";
import { ROUTE } from "../base-app/AppRoutes";
import useAppNavigate from "../../hooks/useAppNavigate";
import { LoadingButton } from "@mui/lab";
import DirectionsCar from "@mui/icons-material/DirectionsCar";
import useSaveItineraryToTrip from "../../hooks/useSaveItineraryToTrip";
import { MapItinerary } from "../../types/map";
import useIsAuthenticated from "../../hooks/useIsAuthenticated";
import { noop } from "lodash";
import { useAppSelector } from "../../redux/hooks";
import useCarSave from "../../hooks/useCarSave";
import { selectReferenceCar } from "../../redux/slices/shed/shedSlice";

type Props = {
  itinerary: MapItinerary | null;
};

const SaveTripButton = ({ itinerary }: Props) => {
  const isAuthenticated = useIsAuthenticated();
  const referenceCar = useAppSelector(selectReferenceCar);

  const navigateTo = useAppNavigate();
  const { loading: loadingTripSave, handleSaveItineraryToTrip } = useSaveItineraryToTrip({
    itinerary,
    onSaved: noop,
  });

  const { loading: loadingCarSave, handleSaveCar: handleSaveCarThenTrip } =
    useCarSave({
      carToSave: referenceCar,
      onSaved: handleSaveItineraryToTrip,
    });

  const handleGuestLogin = useCallback(
    () => navigateTo(ROUTE.LOG_IN_EMAIL_AND_PASSWORD),
    [navigateTo]
  );

  const handleUserTripSave = useCallback(
    () =>
      referenceCar?.id ? handleSaveItineraryToTrip(referenceCar) : handleSaveCarThenTrip(),
    [referenceCar, handleSaveItineraryToTrip, handleSaveCarThenTrip]
  );

  return (
    <LoadingButton
      size="medium"
      color="primary"
      startIcon={<DirectionsCar fontSize="small" />}
      loadingPosition="start"
      variant="contained"
      disabled={!referenceCar || !itinerary}
      loading={loadingTripSave || loadingCarSave}
      onClick={isAuthenticated ? handleUserTripSave : handleGuestLogin}
    >
      Save trip
    </LoadingButton>
  );
};

export default SaveTripButton;
