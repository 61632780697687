import { MapItinerary } from "../types/map";
import { TripEmission, CarState } from "../types/cars";

export const metersToKm = (meters: number) => meters / 1000;
const gramToKilos = (gram: number) => gram / 1000;

const KG_CO2_SAVED_PER_TREE = 25;
const ELECTRIC_NORWAY_CARBON_INTENSITY_KWH_PER_KM = 29;

/**
 * KiloEmission - Car Type [Gas]
 */
const gasEmissionKiloC02 = (gramEmission: number) => gramToKilos(gramEmission);

/**
 * KiloEmission - Car Type [Electric]
 */
const electricEmissionKiloCo2 = (gramEmission: number) =>
  gramToKilos(gramEmission) * ELECTRIC_NORWAY_CARBON_INTENSITY_KWH_PER_KM;

/**
 * KiloEmission - Car Type [Any]
 */
const emissionKiloCo2 = ({
  gramEmission,
  isElectric,
}: {
  gramEmission: number;
  isElectric: boolean;
}) =>
  isElectric
    ? electricEmissionKiloCo2(gramEmission)
    : gasEmissionKiloC02(gramEmission);

/**
 * Convert gramEmission to Trees saved
 */
export const emissionToTrees = ({
  gramEmission,
  distanceMeters,
  isElectric,
}: {
  gramEmission: number;
  distanceMeters: number;
  isElectric: boolean;
}): number =>
  (metersToKm(distanceMeters) * emissionKiloCo2({ gramEmission, isElectric })) /
  KG_CO2_SAVED_PER_TREE;

export const tripEmission = (
  car: CarState | null,
  itinerary: MapItinerary | null
): TripEmission =>
  car && itinerary
    ? {
        distanceMeters: itinerary.distanceMeters,
        totalTrees: emissionToTrees({
          gramEmission: car.emissionCO2,
          distanceMeters: itinerary.distanceMeters,
          isElectric: car.emissionType === "electric",
        }),
        totalKgEmission:
          metersToKm(itinerary.distanceMeters) *
          emissionKiloCo2({
            gramEmission: car.emissionCO2,
            isElectric: car.emissionType === "electric",
          }),
      }
    : {
        distanceMeters: 0,
        totalTrees: 0,
        totalKgEmission: 0,
      };
