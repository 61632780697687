import { Stack, Skeleton, Typography } from "@mui/material";
import CarLogoBrand, { sizeToPixel } from "../cars/CarLogoBrand";
import { useAppSelector } from "../../redux/hooks";
import { selectReferenceCar } from "../../redux/slices/shed/shedSlice";

type Props = {
  loading: boolean;
};

const CarBrandLoader = ({ loading }: Props) => {
  const car = useAppSelector(selectReferenceCar);
  return (
    <Stack alignItems="center">
      {loading ? (
        <>
          <Skeleton
            variant="circular"
            width={sizeToPixel("small")}
            height={sizeToPixel("small")}
          />
          <Typography variant="h6" width={140}>
            <Skeleton />
          </Typography>
        </>
      ) : car?.licensePlate ? (
        <>
          <CarLogoBrand size="large" carBrand={car.carBrand} />
          <Typography mt={1} variant="body2" textTransform="capitalize">
            {`${car.licensePlate}`}
          </Typography>
          <Typography variant="h6" textTransform="capitalize">
            {`${car.carBrand} ${car.carModel}`}
          </Typography>
          <Typography variant="body1" textTransform="capitalize">
            {`${car.emissionType}`}
          </Typography>
        </>
      ) : null}
    </Stack>
  );
};

export default CarBrandLoader;
