import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { Box, Button, styled, Typography } from "@mui/material";
import { AppContainer } from "../base-app/App";

const StyledImg = styled("img")`
  margin-left: "auto";
  margin-right: "auto";
  width: 80%;
  height: 80%;
`;

const PaymentFallbackPage = () => {
  useEffect(
    () =>
      console.warn(
        "[Component] PaymentFallback page - Here we should check the status of the payment prior to rendering"
      ),
    []
  );

  return (
    <AppContainer sx={{ textAlign: "center" }}>
      <Box textAlign="center" maxWidth="100">
        <StyledImg
          alt="brand-logo"
          src={`${process.env.PUBLIC_URL}/logo_EN_horizontal_black.svg`}
        />
      </Box>
      <Box my={2}>
        <Typography variant="h5">Thank you for your donation.</Typography>
        <Typography variant="body2" color="text.secondary">
          You have donated money that will save or plant trees
        </Typography>
      </Box>
      <Button
        startIcon={<DirectionsCarIcon />}
        variant="contained"
        component={Link}
        to="/"
        color="success"
      >
        home
      </Button>
    </AppContainer>
  );
};

export default PaymentFallbackPage;
