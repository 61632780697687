import { useCallback, useState } from "react";
import {
  styled,
  Box,
  Paper,
  Collapse,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import MapLocationField from "./MapLocationField";
import { useAppSelector } from "../../redux/hooks";
import {
  selectHasReferenceTrip,
  selectReferenceItinerary,
  selectReferenceCar,
} from "../../redux/slices/shed/shedSlice";
import { MapLocation } from "types/map";
import { useAppDispatch } from "../../redux/hooks";
import useCarSearch from "hooks/useCarSearch";
import CarBrandLoader from "components/cars/CarBrandLoader";
import CarSelectReference from "../cars/CarSelectReference";
import ForestTree from "../core/ForestTree";
import {
  registerReferenceTrip,
  registerMapToggleValue,
  clearItinerary,
  clearTrip,
} from "../../redux/slices/shed/shedSlice";
import SaveTripButton from "../cars/SaveTripButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import VippsButton from "../payment/VippsButton";
import { emissionToTrees, metersToKm } from "libraries/emission";
import { tripEmission } from "libraries/emission";

const PANEL_WIDTH_MOBILE = 250;
const PANEL_WIDTH_DEFAULT = 400;
const PANEL_DISPLAY_HEIGHT = 500;

const SearchToolbar = styled(Box)({
  display: "flex",
  width: "100%",
});

const PanelDisplay = styled(Box)(({ theme }) => ({
  maxHeight: PANEL_DISPLAY_HEIGHT,
  overflow: "hidden",
  kscroll: "auto",
  padding: theme.spacing(1),
}));

const PaperPanel = styled(Paper)(({ theme }) => ({
  display: "flex",
  top: 0,
  position: "fixed",
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(2),
  padding: theme.spacing(1),
  borderRadius: 16,
}));

type Props = {
  locationFrom: MapLocation;
  locationTo: MapLocation;
};

const MapPanelSearch = ({ locationFrom, locationTo }: Props) => {
  const hasTrip = useAppSelector(selectHasReferenceTrip);
  const car = useAppSelector(selectReferenceCar);

  const [licensePlate, setLicensePlate] = useState("");

  const displayPlate = licensePlate;

  const { smoothLoading, handleSearchCar } = useCarSearch();

  const [isMinimized, setIsMinimized] = useState(false);

  const dispatch = useAppDispatch();

  const itinerary = useAppSelector(selectReferenceItinerary);
  const referenceCar = useAppSelector(selectReferenceCar);

  const { totalKgEmission } = tripEmission(referenceCar, itinerary);

  const tripTreesCount = emissionToTrees({
    gramEmission: car?.emissionCO2 ?? 0,
    distanceMeters: itinerary?.distanceMeters ?? 0,
    isElectric: car?.emissionType === "electric",
  });

  const handleLocationFromChange = useCallback(
    (newFromLocation: MapLocation) =>
      dispatch(
        registerReferenceTrip({
          tripLocation: {
            fromLatitude: newFromLocation.latitude ?? 0,
            fromLongitude: newFromLocation.longitude ?? 0,
            fromAddress: newFromLocation.formattedAddress ?? "",
          },
        })
      ),
    [dispatch]
  );

  const handleLocationToChange = useCallback(
    (newToLocation: MapLocation) =>
      dispatch(
        registerReferenceTrip({
          tripLocation: {
            toLatitude: newToLocation.latitude ?? undefined,
            toLongitude: newToLocation.longitude ?? undefined,
            toAddress: newToLocation.formattedAddress ?? undefined,
          },
        })
      ),
    [dispatch]
  );

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const handleClearTrip = () => {
    dispatch(clearTrip());
    dispatch(clearItinerary());
  };

  return (
    <PaperPanel
      elevation={3}
      sx={{
        width: { xs: PANEL_WIDTH_MOBILE, sm: PANEL_WIDTH_DEFAULT },
        maxHeight: "80%",
        overflowY: "auto",
      }}
    >
      {isMinimized && (
        <Button
          sx={{ mt: 1, mb: 2, justifyContent: "start", display: "flex" }}
          onClick={toggleMinimize}
        >
          <ArrowForwardIosIcon /> Show
        </Button>
      )}
      {!isMinimized && (
        <Stack width="100%">
          <SearchToolbar>
            <Stack width="100%" mt={1}>
              <Box display="flex" justifyContent={"space-between"} mb={2}>
                <Button onClick={handleClearTrip}>New Trip</Button>
                <Button onClick={toggleMinimize}>
                  <ArrowBackIosIcon /> Hide
                </Button>
              </Box>
              <CarSelectReference
                size="small"
                onSearchCar={handleSearchCar}
                onInputChange={setLicensePlate}
                inputValue={displayPlate}
              />
              <CarBrandLoader loading={smoothLoading} />
              <MapLocationField
                label="From"
                location={locationFrom}
                onChange={handleLocationFromChange}
              />
              <MapLocationField
                label="To"
                location={locationTo}
                onChange={handleLocationToChange}
              />
            </Stack>
          </SearchToolbar>

          <Collapse in={hasTrip}>
            <PanelDisplay>
              <Typography mt={2} textAlign="center">
                CO2 is the same as {tripTreesCount.toFixed(2)}
              </Typography>

              <Typography textAlign="center">
                Rainforest trees absorbs in 1 year
              </Typography>
              <ForestTree
                treeCount={tripTreesCount}
                donated={0}
                leftToDonate={0}
              />

              <Typography textAlign="center">
                {itinerary && metersToKm(itinerary?.distanceMeters).toFixed(2)}{" "}
                KM
              </Typography>

              <Typography textAlign="center">
                {totalKgEmission.toFixed(2)} KG CO2
              </Typography>

              <Box mt={2} display="flex" gap={1}>
                <SaveTripButton itinerary={itinerary} />
                <VippsButton
                  onClick={() => dispatch(registerMapToggleValue(true))}
                  loading={false}
                />
              </Box>
            </PanelDisplay>
          </Collapse>
        </Stack>
      )}
    </PaperPanel>
  );
};

export default MapPanelSearch;
