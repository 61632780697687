export const DEFAULT_DONATION_AMOUNT = 100;
export const UNSET_DONATION_SUGGESTION = -1;

const RAINFOREST_TREES_PER_FINANCIAL_UNIT = 5;

export const treesToDonationAmount = (trees: number): number =>
  trees ? Number((trees * RAINFOREST_TREES_PER_FINANCIAL_UNIT).toFixed(2)) : 0;

export const donationAmountToTrees = (donation: number): number =>
  Number((donation / RAINFOREST_TREES_PER_FINANCIAL_UNIT).toFixed(2));
