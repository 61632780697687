import {
  Backdrop,
  styled,
  Paper,
  Box,
  SwipeableDrawer,
  Button,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  selectToggleDrawer,
  registerMapToggleValue,
} from "../../redux/slices/shed/shedSlice";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import CarDonation from "../cars/CarDonation";
import useAppNavigate from "../../hooks/useAppNavigate";
import { ROUTE } from "../base-app/AppRoutes";

const DRAWER_BLEEDING = 56;
const DRAWER_PULLER_WIDTH = 30;

const PaperBackground = styled(Paper)(({ theme }) => ({
  top: -DRAWER_BLEEDING,
  visibility: "visible",
  right: 0,
  left: 0,
}));

const Puller = styled(Box)(({ theme }) => ({
  width: DRAWER_PULLER_WIDTH,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: `calc(50% - ${DRAWER_PULLER_WIDTH / 2}px)`,
}));

const MapDrawer = () => {
  const dispatch = useAppDispatch();
  const isDrawerOpen = useAppSelector(selectToggleDrawer);
  const navigateTo = useAppNavigate();

  const toggleDrawer = (value: Boolean) => {
    dispatch(registerMapToggleValue(value));
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
        open={Boolean(isDrawerOpen)}
        onClick={() => toggleDrawer(false)}
      />
      <SwipeableDrawer
        anchor={"bottom"}
        onClose={() => toggleDrawer(false)}
        ModalProps={{
          keepMounted: true,
          BackdropProps: {
            style: { backgroundColor: "transparent" },
          },
        }}
        onOpen={() => toggleDrawer}
        open={Boolean(isDrawerOpen)}
      >
        <PaperBackground elevation={2}>
          <Puller />
          <CarDonation />
          <Button onClick={() => navigateTo(ROUTE.PAYMENT_TERMS)}>
            Salgsvilkår
          </Button>
        </PaperBackground>
      </SwipeableDrawer>
    </>
  );
};

export default MapDrawer;
