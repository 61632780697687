import { useCallback } from "react";
import { Box, IconButton, styled, Paper, Typography } from "@mui/material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { BottomBarPlaceholder } from "../base-app/BottomBar";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  registerMapToggleValue,
  selectToggleDrawer,
} from "../../redux/slices/shed/shedSlice";

const PanelLayout = styled(Box)(({ theme }) => ({
  bottom: 0,
  position: "fixed",
}));

const PaperPanel = styled(Paper)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(4),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
}));

const MapPanelActions = () => {
  const dispatch = useAppDispatch();

  const isDrawerOpen = useAppSelector(selectToggleDrawer);

  const handleToggle = useCallback(() => {
    dispatch(registerMapToggleValue(!isDrawerOpen));
  }, [dispatch, isDrawerOpen]);

  return (
    <PanelLayout>
      <PaperPanel elevation={3}>
        <IconButton onClick={handleToggle}>
          {isDrawerOpen ? <ArrowCircleDownIcon /> : <ArrowCircleUpIcon />}
        </IconButton>
        <Typography textAlign="center" fontSize={11}>
          DONATE
        </Typography>
      </PaperPanel>
      <BottomBarPlaceholder />
    </PanelLayout>
  );
};

export default MapPanelActions;
