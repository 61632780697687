import { useCallback } from "react";
import { Button, Box, Card, CardActions } from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import ShareIcon from "@mui/icons-material/Share";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { TripState } from "types/user";
import { selectCarById } from "redux/slices/shed/shedSlice";
import CarLogoBrand from "components/cars/CarLogoBrand";
import { useAppDispatch } from "redux/hooks";
import useAppNavigate from "hooks/useAppNavigate";
import { tripStateToTripLocation } from "libraries/trip";
import { ROUTE } from "components/base-app/AppRoutes";
import useShareTrip from "hooks/trips/useShareTrip";
import TripCard from "./TripCard";

import {
  registerReferenceCar,
  registerReferenceTrip,
} from "redux/slices/shed/shedSlice";

type Props = {
  trip: TripState;
};

const TripOverview = ({ trip }: Props) => {
  const { carId, tripId } = trip;
  const tripCar = useSelector(selectCarById(carId));

  const { handleShareTrip } = useShareTrip({
    trip,
    carLicensePlate: tripCar.licensePlate,
  });

  const dispatch = useAppDispatch();
  const navigateTo = useAppNavigate();

  const handleMapClick = useCallback(() => {
    if (!tripCar) {
      return;
    }

    dispatch(registerReferenceCar(tripCar));
    dispatch(
      registerReferenceTrip({
        tripId: tripId || null,
        tripLocation: tripStateToTripLocation(trip),
      })
    );
    navigateTo(ROUTE.MAP);
  }, [navigateTo, dispatch, tripCar, trip, tripId]);

  return (
    <Card>
      <TripCard trip={trip} tripCar={tripCar} />

      <CardActions
        sx={{
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Box display="flex" width="100%">
          <Button
            startIcon={<MapIcon />}
            disabled={!tripCar}
            onClick={handleMapClick}
          >
            Map
          </Button>
          <Button startIcon={<ShareIcon />} onClick={handleShareTrip}>
            Share
          </Button>
          <Box flexGrow={1} />
          {tripCar && <CarLogoBrand size="large" carBrand={tripCar.carBrand} />}
        </Box>
      </CardActions>
    </Card>
  );
};

export default TripOverview;
