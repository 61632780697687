import { useState, useCallback, useEffect } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {
  UNSET_DONATION_SUGGESTION,
  treesToDonationAmount,
} from "libraries/donation";
import PhoneNumberInput from "components/payment/PhoneNumberInput";
import useHandleVippsPayment from "hooks/useHandleVippsPayment";
import { useAppSelector } from "redux/hooks";
import VippsButton from "components/payment/VippsButton";
import {
  selectReferenceCar,
  selectReferenceItinerary,
} from "redux/slices/shed/shedSlice";
import useSaveItineraryToTrip from "hooks/useSaveItineraryToTrip";
import useCarSave from "hooks/useCarSave";
import useDisplayMessage from "hooks/useDisplayMessage";

import { selectReferenceTripEmission } from "redux/slices/shed/shedSlice";

const CarDonation = () => {
  const { displayMessage } = useDisplayMessage();
  const [isMobileNumberError, setMobileNumberError] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [selectedAmount, setSelectedAmount] = useState<number | null>(null);
  const { distanceMeters, totalTrees, totalKgEmission } = useAppSelector(
    selectReferenceTripEmission
  );
  const donationSuggestion = treesToDonationAmount(totalTrees);
  useEffect(() => setSelectedAmount(donationSuggestion), [donationSuggestion]);

  const referenceCar = useAppSelector(selectReferenceCar);
  const itinerary = useAppSelector(selectReferenceItinerary);

  const { handleVippsPayment, loadingPayment } = useHandleVippsPayment({
    co2FootprintInKg: totalKgEmission,
    donation: selectedAmount ?? donationSuggestion,
    distance: distanceMeters,
    mobileNumber: mobileNumber,
  });

  const { handleSaveItineraryToTrip } = useSaveItineraryToTrip({
    itinerary,
    onSaved: (tripId, carId) => handleVippsPayment(tripId, carId),
  });

  const { handleSaveCar } = useCarSave({
    carToSave: referenceCar,
    onSaved: (savedCar) => handleSaveItineraryToTrip(savedCar),
  });

  const handleDonationChange = useCallback(
    (event: SelectChangeEvent<number>) =>
      setSelectedAmount(Number(event.target.value)),
    []
  );

  const payWithVipps = () => {
    const isValidPhoneNumber = /^\d{8}$/.test(mobileNumber);

    if (!isValidPhoneNumber) {
      displayMessage("Add a valid phone number to donate", "info");
      setMobileNumberError(true);
      return;
    }

    handleSaveCar();
  };

  return (
    <Box display="flex" flexDirection="column" p={3} gap={2}>
      <Typography textAlign="center">Become a Rainforest Guardian</Typography>

      <PhoneNumberInput
        mobileNumber={mobileNumber}
        isMobileNumberError={isMobileNumberError}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setMobileNumber(event.target.value)
        }
      />
      <FormControl>
        <InputLabel id="donation-input">Donate</InputLabel>
        <Select
          labelId="donation-simple-select-label"
          id="donation-simple-select-label"
          label="Donate"
          value={selectedAmount ?? donationSuggestion}
          onChange={handleDonationChange}
        >
          {donationSuggestion !== UNSET_DONATION_SUGGESTION && (
            <MenuItem value={donationSuggestion}>
              {donationSuggestion}kr (current trip)
            </MenuItem>
          )}
          <MenuItem value={5}>5kr (1 tree)</MenuItem>
          <MenuItem value={25}>25kr (5 trees)</MenuItem>
          <MenuItem value={50}>50 (10 trees)</MenuItem>
          <MenuItem value={100}>100kr (20 trees)</MenuItem>
        </Select>
      </FormControl>
      <VippsButton onClick={payWithVipps} loading={loadingPayment} />
      <Typography style={{ fontSize: "16px" }}>
        Did you know that for as little as 5 kroner, the Rainforest Foundation
        can save 1 tree containing more than 1 ton CO
        <span style={{ fontSize: "12px", verticalAlign: "super" }}>2</span>?
      </Typography>
      <Typography style={{ fontSize: "16px" }}>
        By supporting the Rainforest Foundation, you directly contribute to
        global emission reductions and biodiversity.
      </Typography>
    </Box>
  );
};

export default CarDonation;
