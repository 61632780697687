import { useCallback } from "react";
import {
  styled,
  Stack,
  Card,
  CardContent,
  Typography,
  Chip,
  Box,
  Button,
} from "@mui/material";
import { TripState } from "types/user";
import TripCommuteFrequency from "components/trips/TripCommuteFrequency";
import useTripCommuteFrequency from "hooks/trips/useTripCommuteFrequency";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MovingIcon from "@mui/icons-material/Moving";
import Co2OutlinedIcon from "@mui/icons-material/Co2Outlined";
import ParkOutlinedIcon from "@mui/icons-material/ParkOutlined";
import useDisplayMessage from "hooks/useDisplayMessage";
import { useMutation } from "@apollo/client";
import { DELETE_TRIP } from "graphql/queries";
import { removeTrip } from "redux/slices/shed/shedSlice";
import { useAppDispatch } from "redux/hooks";
import { CarState } from "types/cars";
import { metersToKm } from "libraries/emission";
import { emissionToTrees } from "libraries/emission";

type Props = {
  trip: TripState;
  tripCar: CarState;
};

const RowBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

const TripCard = ({ trip, tripCar }: Props) => {
  const tripTreesCount = emissionToTrees({
    gramEmission: tripCar.emissionCO2,
    distanceMeters: trip.distanceMeters,
    isElectric: tripCar.emissionType === "electric",
  });

  const { displayMessage } = useDisplayMessage();
  const [deleteTrip] = useMutation(DELETE_TRIP);

  const dispatch = useAppDispatch();

  const handleDeleteCar = useCallback(async () => {
    if (!trip.tripId) return;

    await deleteTrip({ variables: { tripId: trip.tripId } });

    dispatch(removeTrip(trip.tripId));
    displayMessage("Trip has been removed", "success");
  }, [trip.tripId, displayMessage, dispatch, deleteTrip]);

  const { commuteFrequency, increaseFrequency, decreaseFrequency } =
    useTripCommuteFrequency({
      trip,
    });

  return (
    <Card variant="outlined">
      <CardContent>
        <Box
          display="flex"
          pb={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="body1">
            License Plate: <Chip label={trip.licensePlate} />
          </Typography>
          <Button disabled={!tripCar} onClick={handleDeleteCar}>
            Delete
          </Button>
        </Box>
        <Stack gap={0.5} pb={4}>
          <RowBox>
            <CalendarMonthOutlinedIcon fontSize="small" />
            <Typography variant="body2" component="p">
              Date: {trip.tripTimeStamp}
            </Typography>
          </RowBox>
          <RowBox>
            <LocationOnOutlinedIcon fontSize="small" />
            <Typography variant="body2" component="p">
              From: {trip.fromAddress}
            </Typography>
          </RowBox>
          <RowBox>
            <LocationOnOutlinedIcon fontSize="small" />
            <Typography variant="body2" component="p">
              To: {trip.toAddress}
            </Typography>
          </RowBox>
          <RowBox>
            <MovingIcon fontSize="small" />
            <Typography variant="body2" component="p">
              Distance:
              {(metersToKm(trip.distanceMeters) * commuteFrequency).toFixed(2)}
              KM
            </Typography>
          </RowBox>
          <RowBox>
            <Co2OutlinedIcon fontSize="small" />
            <Typography variant="body2" component="p">
              Kg CO<sub>2</sub>:{" "}
              {(trip.totalKgEmission * commuteFrequency).toFixed(2)}
            </Typography>
          </RowBox>
          <RowBox>
            <ParkOutlinedIcon fontSize="small" />
            <Typography variant="body2">
              Tree equivalent :{(tripTreesCount * commuteFrequency).toFixed(1)}
            </Typography>
          </RowBox>
        </Stack>

        <TripCommuteFrequency
          commuteFrequency={commuteFrequency}
          increaseFrequency={increaseFrequency}
          decreaseFrequency={decreaseFrequency}
        ></TripCommuteFrequency>
      </CardContent>
    </Card>
  );
};

export default TripCard;
